import React, { useState, useEffect, useRef } from 'react';
import api from '../../config/Api';
import { getSpinnerIcon, getSolidCheckCircleIcon, getSolidXIcon, getCalenderIcon, getMinusIcon, getPlusIcon, getViewListIcon, getViewGridIcon, getRefreshIcon, getChevronDownIcon, getChevronUpIcon} from '../../helper/SvgHelper';
import EmailFormatValidator, {IntegerValidator} from '../../helper/ValidationHelper';
import DatePicker from "react-datepicker";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import "../../styles/custom.css";

const SampleSubmissionForm = ({selectedRow,  setSelectedRow, setShowSubmissionFormDialog, sampleSubmissionList, setSampleSubmissionList, downloadSampleSubmissionForm}) => {

    const responseMsgSectionRef = useRef(null);
    const regTabBottomRef = useRef(null);

    const [currentTab, setCurrentTab] = useState('Client Details');

    const [productCategoryTests, setProductCategoryTests] = useState(null);
    const [clientList, setClientList] = useState(null);
    const [sampleTypeList, setSampleTypeList] = useState(null);
    const [recentSubmissionList, setRecentSubmissionList] = useState(null);
    const [keyWordList, setKeyWordList] = useState(null);
    const [loading, setLoading] = useState(false);
    const [noOfJob, setNoOfJob] = useState(1);

    // Submission template
    const [submissionTemplateId, setSubmissionTemplateId] = useState(null);
    const [sampleTypeId, setSampleTypeId] = useState(null);

    const [responseStatus, setResponseStatus] = useState(null);
    const [responseMsg, setResponseMsg] = useState(null);
    const [showResponseMsg, setShowResponseMsg] = useState(false);

    // Client Details
    const [clientRef, setClientRef] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [clientReference, setClientReference] = useState(null);
    const [address, setAddress] = useState(null);
    const [phone, setPhone] = useState(null);
    const [quoteNo, setQuoteNo] = useState(null);
    const [orderNo, setOrderNo] = useState(null);
    const [contactPerson, setContactPerson] = useState(null);
    const [email, setEmail] = useState(null);
    const [submitter, setSubmitter] = useState(null);
    const [submitterEmail, setSubmitterEmail] = useState([]);
    const [invoiceTo, setInvoiceTo] = useState(null);
    const [reportToEmail, setReportToEmail] = useState(null);

    // Reg Information
    const [caiqtestId, setCaiqtestId] = useState(null); // currently disabled for sample submission
    const [regTidRegRef, setRegTidRegRef] = useState(null);
    const [currentURegRef, setCurrentURegRef] = useState(null);
    const [showCurrentURegRef, setShowCurrentURegRef] = useState(true);
    const [sampleName, setSampleName] = useState(null); // job description
    const [noOfSample, setNoOfSample] = useState(1);
    const [regPriority, setRegPriority] = useState('0');
    const [regStatus, setRegStatus] = useState(null);
    const [dueDate, setDueDate] = useState(null);
    const [reportFormat, setReportFormat] = useState('IANZ_REPORT');
    const [productCategory, setProductCategory] = useState(null);
    const [labGroup, setLabGroup] = useState('CHEMISTRY');
    //Reg Information should mapped
    const selectedAdditionalInfoDefaultOptions = 
    (selectedRow && selectedRow.Status) === "Accepted"  ? 
        [{field : 'CLIENT_BATCH',  value : ''},
        {field : 'STOR_REQUIRE',  value : ''},
        {field : 'QUANTITY',  value : ''},
        {field : 'WEIGHT_UNIT',  value : ''},
        {field : 'PACK_MODE',  value : ''},
        {field : 'TRANSITIONAL',  value : ''},
        {field : 'SUB_CONTRACTED',  value : ''}]
        :
        [{field : 'CLIENT_BATCH',  value : ''},
        {field : 'STOR_REQUIRE',  value : ''},
        {field : 'QUANTITY',  value : ''},
        {field : 'WEIGHT_UNIT',  value : ''},
        {field : 'PACK_MODE',  value : ''}]
    const [selectedAdditionalInfo, setSelectedAdditionalInfo] = useState(selectedAdditionalInfoDefaultOptions);
    const [otherStorageRequirement, setOtherStorageRequirement] = useState('');
    const [prePackagedType, setPrePackagedType] = useState('');
    
    const [recentURegRefs, setRecentURegRefs] = useState(false);
    
    // Sample Information
    const [sampleInfo, setSampleInfo] = useState([{SAMPLE_ID : '',  SAMPLE_INFO : '', SAMPLE_TIME : null, DUE_DATE : ''}]);
    const [additionalSampleInfoColumn, setAdditionalSampleInfoColumn] = useState(['']);

    // Chain of Custoday Details
    const [receivedDateTime, setReceivedDateTime] = useState(new Date());
    const [temperatureCondition, setTemperatureCondition] = useState(null);
    const [receivedBy, setReceivedBy] = useState(null);
    const [secondCheckedBy, setSecondCheckedBy] = useState(null);
    const [reportDate, setReportDate] = useState(null);
    const [invoiceDate, setInvoiceDate] = useState(null);
    const [additionalComments, setAdditionalComments] = useState(null);
    
    // Test Assign
    const [showSampleTestGrid, setShowSampleTestGrid] = useState(false);
    const [testInfo, setTestInfo] = useState([{procedRef : '',  testRef : '',  methodRef : '', note : '', unit : ''}]);
    const [testInfoErrMsg, setTestInfoErrMsg] = useState(['']);
    const [sampleTestAssignment, setSampleTestAssignment] = useState([[]]);
    const [uploadFile, setUploadFile] = useState('');
    const [uploadFileErrMsg, setUploadFileErrMsg] = useState(null);

    // Limit Assign
    const [showSampleLimitGrid, setShowSampleLimitGrid] = useState(false); // Sample Limits are not used
    const [clientLimitGroupList, setClientLimitGroupList] = useState([]);
    const [limitGroupAssign, setLimitGroupAssign] = useState([[{id:'', limitType:'', descrip:'', priority:''}]]);
    const [limitGroupAssignErrMsg, setLimitGroupAssignErrMsg] = useState([['']]);

    const [manualEnteredLimit, setManualEnteredLimit] = useState([]);
    const [showManualEntryLimitGrid, setShowManualEntryLimitGrid] = useState(false); // SR entered Limits

    // Contact
    const [mainContact, setMainContact] = useState(null);
    const [contactCcList, setContactCcList] = useState([]);

    const submissionNavItem = selectedRow && selectedRow.Status === "Accepted"  ? 
    [
        {label: 'Client Details', value: 'Client Details'},
        {label: 'Registration Info', value: 'Registration Information'},
        {label: "Sample's Info", value: "Sample's Information"},
        {label: 'Chain of Custody Details', value: 'Chain of Custody Details'},
        {label: 'Test Assgin', value: 'Test Assginment'},
        {label: 'Limit Assign', value: 'Limit Assignment'},
        {label: 'Contact', value: 'Contact'},
    ]
    :
    [
        {label: 'Client Details', value: 'Client Details'},
        {label: 'Registration Info', value: 'Registration Information'},
        {label: 'Test Assgin', value: 'Test Assginment'},
        {label: 'Chain of Custody Details', value: 'Chain of Custody Details'},
    ];

    const customOptions = [
        {label: 'Pending', value: 1},
        {label: 'Incomplete', value: 2},
        {label: 'Accepted', value: 3},
        {label: 'On Hold', value: 4},
        {label: 'Cancelled', value: 5},
    ];
    const buttonGroupOptionRef = useRef(null);
    const [showButtonGroupOptions, setShowButtonGroupOptions] = useState(false);

    const selectButtonGroupOption = async (option) => {
        submitSampleSubmission(option.value);
    }

    const ResponseMessageField = () => {
        return (
            <div ref={responseMsgSectionRef} className={`${responseStatus ? 'bg-green-50' : 'bg-red-50'} rounded-md p-4 mt-1`}>
                <div className="flex">
                    <div className="flex-shrink-0">
                        { responseStatus ? getSolidCheckCircleIcon() : getSolidXIcon() }
                    </div>
                    <div className="ml-3">
                        <p className={`${responseStatus ? 'text-green-800' : 'text-red-800'} text-sm font-medium`}>{responseMsg}</p>
                    </div>
                </div>
            </div>
        )
    }

    class ReceivedDateTimePicker extends React.Component {
        render() {
            return (
                    <button onClick={this.props.onClick} className="ml-4 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm text-gray-600 font-medium rounded-md bg-white hover:bg-gray-100 focus:outline-none">
                        {this.props.value}
                        {getCalenderIcon()}
                    </button>
            );
        }
    };

    class DueDatePicker extends React.Component {
        render() {
            return (
                <div className="flex">
                    <button onClick={this.props.onClick} className="ml-4 inline-flex items-center px-16 py-1.5 border border-gray-300 shadow-sm text-sm text-gray-600 font-medium rounded-md bg-white hover:bg-gray-100 focus:outline-none">
                        {this.props.value}
                        {getCalenderIcon()}
                    </button>
                    {dueDate && <div className='mx-2 mt-3 text-sm text-indigo-600 hover:text-indigo-900 cursor-pointer'
                        onClick={ () => { setDueDate(null); } }
                        >
                        Remove
                    </div>}
                </div>
            );
        }
    };

    class SampleDueDatePicker extends React.Component {
        render() {
            return (
                <button onClick={this.props.onClick} className={`ml-4 inline-flex items-center ${this.props.value ? "px-7" : "px-4"} py-1 border border-gray-300 shadow-sm text-sm text-gray-600 font-medium rounded-md bg-white hover:bg-gray-100 focus:outline-none`}>
                    {this.props.value ? this.props.value : <div className="-ml-2">{getCalenderIcon()}</div>}
                </button>
            );
        }
    };

    // class AdditionalInfoDatePicker extends React.Component {
    //     render() {
    //         return (
    //             <button onClick={this.props.onClick} className={`ml-2 inline-flex items-center ${this.props.value ? "px-75px py-4.5px" : "px-105px py-3px"} border border-gray-300 shadow-sm text-sm text-gray-600 font-medium rounded-md bg-white hover:bg-gray-100 focus:outline-none`}>
    //                 {this.props.value ? this.props.value : <div className="-ml-2">{getCalenderIcon()}</div>}
    //             </button>
    //         );
    //     }
    // };

    class ReportDatePicker extends React.Component {
        render() {
            return (
                <button onClick={this.props.onClick} className="ml-4 inline-flex items-center px-20 py-1.5 border border-gray-300 shadow-sm text-sm text-gray-600 font-medium rounded-md bg-gray-100 hover:bg-gray-100 focus:outline-none">
                    {this.props.value}
                    {getCalenderIcon()}
                </button>
            );
        }
    };

    class InvoiceDatePicker extends React.Component {
        render() {
            return (
                <button onClick={this.props.onClick} className="ml-4 inline-flex items-center px-20 py-1.5 border border-gray-300 shadow-sm text-sm text-gray-600 font-medium rounded-md bg-gray-100 hover:bg-gray-100 focus:outline-none">
                    {this.props.value}
                    {getCalenderIcon()}
                </button>
            );
        }
    };

    const clearClienDetails = () => {
        setCompanyName(null);
        setClientRef(null);
        setClientReference(null);
        setAddress(null);
        setPhone(null);
        setQuoteNo(null);
        setOrderNo(null);
        setContactPerson(null);
        setEmail(null);
        setSubmitter(null);
        setSubmitterEmail([]);
        setInvoiceTo(null);
        setAdditionalComments(null);
    }

    const clearSampleTestAssignment = () => {
        let tempSampleAssignment = [];
        for (let i = 0; i < noOfSample; i++) {
            tempSampleAssignment.push([]);
        }
        setSampleTestAssignment(tempSampleAssignment); 
    }

    const setInputClientRef = (value) => {
        clearClienDetails();
        setLimitGroupAssign([[{id:'', limitType:'', descrip:'', priority:''}]]);
        setSampleTypeId(null);
        setSubmissionTemplateId(null);
        setClientRef(value);
    }

    // const setInputSubmitterEmail = (value) => {
    //     setSubmitterEmail(value);
    //     if(value) {
    //         const validationResult = EmailFormatValidator(value);
    //         setSubmitterEmailErrMsg(validationResult ? null : '* Invalid email format.');
    //         return;
    //     }
    //     setSubmitterEmailErrMsg(null);
    // }

    const setInputOtherStorageRequirement = (value) => {
        if(!value) { 
            let tempArray = [...selectedAdditionalInfo];
            tempArray[1].value = '';
            setSelectedAdditionalInfo(tempArray);
        }
        setOtherStorageRequirement(value);
    }

    const setInputPackingMode = (value) => {
        let tempArray = [...selectedAdditionalInfo];
        tempArray[4].value = value;
        setSelectedAdditionalInfo(tempArray);
        setPrePackagedType('');
    }

    const setInputPrePackagedType = (value) => {
        if(!value) { 
            let tempArray = [...selectedAdditionalInfo];
            tempArray[4].value = '';
            setSelectedAdditionalInfo(tempArray);
        }
        setPrePackagedType(value);
    }

    const setInputQuantity = (value) => {
        let tempArray = [...selectedAdditionalInfo];
        tempArray[2].value = value;
        setSelectedAdditionalInfo(tempArray);
    }

    const setInputWeightUnit = (value) => {
        let tempArray = [...selectedAdditionalInfo];
        tempArray[3].value = value;
        setSelectedAdditionalInfo(tempArray);
    }

    const spliceFromTestInfoArray = (index) => {
        if(testInfo && testInfo.length === 1) { return; }

        let tempSampleTestAssignment = [...sampleTestAssignment];
        sampleTestAssignment.forEach((assignment, i) => {
            let testIndex = assignment.indexOf(testInfo[index].testRef)
            if(testIndex >= 0) {
                tempSampleTestAssignment[i].splice(testIndex, 1);
            }
        });
        setSampleTestAssignment(tempSampleTestAssignment);

        let tempTestInfoArray = [...testInfo];
        tempTestInfoArray.splice(index, 1);
        setTestInfo(tempTestInfoArray);

        let tempTestInfoErrMsgArray = [...testInfoErrMsg];
        tempTestInfoErrMsgArray.splice(index, 1);
        setTestInfoErrMsg(tempTestInfoErrMsgArray);
    }

    const pushIntoTestInfoArray = () => {
        setTestInfo([...testInfo, {procedRef : '', testRef : '',  methodRef : '', note : '', unit : ''}]);
        setTestInfoErrMsg([...testInfoErrMsg, '']);
    }

    const pushIntoGlobalLimitAssignArray = () => {
        let tempArray = [...limitGroupAssign];
        tempArray[0].push({id:'', limitType:'', descrip:'', priority:''});
        setLimitGroupAssign(tempArray);

        let tempErrMsgArray = [...limitGroupAssignErrMsg];
        tempErrMsgArray[0].push('');
        setLimitGroupAssignErrMsg(tempErrMsgArray);
    }

    const spliceFromGlobalLimitAssignArray = (index) => {
        if(limitGroupAssign && limitGroupAssign[0].length === 1) { return; }

        let tempArray = [...limitGroupAssign];
        tempArray[0].splice(index, 1);
        setLimitGroupAssign(tempArray);

        let tempErrMsgArray = [...limitGroupAssignErrMsg];
        tempErrMsgArray[0].splice(index, 1);
        setLimitGroupAssignErrMsg(tempErrMsgArray);
    }

    const setInputTestInfo = (index, value, type) => {
        let tempTestInfoArray = [...testInfo];

        switch(type) {
            case 'test':
                if (tempTestInfoArray.some(t => t.testRef === value)) {
                    let tempTestInfoErrMsg = [...testInfoErrMsg];
                    tempTestInfoErrMsg[index] = '* Test is duplicated.';
                    setTestInfoErrMsg(tempTestInfoErrMsg);
                }
                else {
                    let tempTestInfoErrMsg = [...testInfoErrMsg];
                    tempTestInfoErrMsg[index] = '';
                    setTestInfoErrMsg(tempTestInfoErrMsg);
                }

                tempTestInfoArray[index].testRef = value;

                if(value) {
                    let productCategoryTestObj = productCategoryTests.filter(o => o.TestRef === value);
                    tempTestInfoArray[index].procedRef = productCategoryTestObj[0].ProcedRef;
                    tempTestInfoArray[index].methodRef = productCategoryTestObj[0].MethodRef;
                    tempTestInfoArray[index].unit = productCategoryTestObj[0].Units;
                }
                else {
                    tempTestInfoArray[index].procedRef = '';
                    tempTestInfoArray[index].methodRef = '';
                    tempTestInfoArray[index].unit = '';
                }
                break;
            case 'note':
                tempTestInfoArray[index].note = value;
                break;
        }
        setTestInfo(tempTestInfoArray);
    }

    const setManualEnteredTestLimit = (index, tRef, value, type) => {
        if (manualEnteredLimit[index]) { // found
            setManualEnteredLimit((prevItems) =>
                prevItems.map((row, i) =>
                  i === index ? { ...row, [type]: value } : row
                )
            );
        } 
        else { // not found
          let tempEnteredLimit = [...manualEnteredLimit];
          let newObj = { testRef: tRef, 'lower': null, 'upper': null };
          newObj[type] = value;
          tempEnteredLimit[index] = newObj;
          setManualEnteredLimit(tempEnteredLimit);
        }
    };


    const setInputSelectedAdditionalInfo = (index, field, value, type) => {
        if(type === 'Field' && selectedAdditionalInfo.filter(s => s.field === field).length > 0) { field = ''; }
        let tempSelectedAdditionalInfo = [...selectedAdditionalInfo];
        if(type === 'Field') { tempSelectedAdditionalInfo[index].field = field; }
        if(type === 'Value') { tempSelectedAdditionalInfo[index].value = value; }
        setSelectedAdditionalInfo(tempSelectedAdditionalInfo);
    }

    const pushIntoSelectedAdditionalInfoArray = () => {
        let emptyObj = {field : '',  value : ''};
        let tempSelectedAdditionalInfo = [...selectedAdditionalInfo, emptyObj];
        setSelectedAdditionalInfo(tempSelectedAdditionalInfo);
        regTabBottomRef.current?.scrollIntoView();
    }

    const spliceFromSelectedAdditionalInfoArray = (index) => {
        if(index < 5) { return; }
        let tempSelectedAdditionalInfo = [...selectedAdditionalInfo];
        tempSelectedAdditionalInfo.splice(index, 1);
        if(tempSelectedAdditionalInfo && tempSelectedAdditionalInfo.length === 0) { 
            tempSelectedAdditionalInfo.push({field : '',  value : ''});
        }
        setSelectedAdditionalInfo(tempSelectedAdditionalInfo);
    }

    const getSampleSubmissionDetails = async (sampleSubmissionId) => {
        setLoading(true);
        setShowResponseMsg(false);
        setResponseStatus(null);
        setShowResponseMsg(null);
        await api.get('/lims/client-sample-submission-details', { params: { sampleSubmissionId: sampleSubmissionId, caiqtestId: caiqtestId } })
                .then(res => {
                    if(res.data.success) { 
                        let details = res.data.data;

                        // Client Details
                        setClientRef(details.clientDetails.clientRef);
                        setCompanyName(details.clientDetails.companyName);
                        setAddress(details.clientDetails.address);
                        setPhone(details.clientDetails.phone);
                        setClientReference(details.clientDetails.clientReference);
                        setEmail(details.clientDetails.email);                        
                        setContactPerson(details.clientDetails.contactPerson);
                        setInvoiceTo(details.clientDetails.invoiceTo);
                        setOrderNo(details.clientDetails.orderNo);
                        setQuoteNo(details.clientDetails.quoteNo);
                        setSubmitterEmail(details.clientDetails.submitterEmail);
                        setSubmitter(details.clientDetails.submitter);
                        setReportToEmail(details.clientDetails.reportToEmail ? details.clientDetails.reportToEmail : null);                     
                        
                        //Reg Info
                        setProductCategory(details.regInfo.productCategory);
                        setSampleName(details.regInfo.sampleName);
                        setDueDate(details.regInfo.dueDate ? new Date(details.regInfo.dueDate) : null);
                        setRegStatus(details.regInfo.regStatus);
                        setRegPriority(details.regInfo.regPriority || '0');
                        setReportFormat(details.regInfo.reportFormat || 'IANZ_REPORT');
                        setLabGroup(details.regInfo.labGroup || 'CHEMISTRY');
                        setNoOfSample(details.regInfo.noOfSample || 1);
                        setSelectedAdditionalInfo(details.regInfo.selectedAdditionalInfo);
                        setPrePackagedType(details.regInfo.prePackagedType);
                        setOtherStorageRequirement(details.regInfo.otherStorageRequirement);
                        setRegTidRegRef(selectedRow ? details.regInfo.regTidRegRef : null);
                        setCurrentURegRef(selectedRow ? details.regInfo.currentRegRef : null);
                        setRecentURegRefs(selectedRow ? details.regInfo.recentRegRef : null);

                        //Chain of Custody Details
                        if(selectedRow) {
                            setReceivedDateTime(details.chainOfCustodyDetails.receivedDateTime ? new Date(details.chainOfCustodyDetails.receivedDateTime) : new Date());
                            setReceivedBy(details.chainOfCustodyDetails.receivedBy);
                            setSecondCheckedBy(details.chainOfCustodyDetails.secondCheckedBy);
                            setReportDate(details.chainOfCustodyDetails.reportDate ? new Date(details.chainOfCustodyDetails.reportDate) : null);
                            setInvoiceDate(details.chainOfCustodyDetails.invoiceDate ? new Date(details.chainOfCustodyDetails.invoiceDate) : null);
                            setTemperatureCondition(details.chainOfCustodyDetails.temperatureCondition);
                        }
                        setAdditionalComments(details.chainOfCustodyDetails.additionalComments);

                        // Sample info
                        setSampleInfo(details.sampleInfo);
                        setAdditionalSampleInfoColumn(details.additionalSampleInfoColumn);

                        //Test Information
                        setTestInfo(details.testInfo.testInfo); //Should not be empty array
                        let tempTestInfoErrMsgArray = [];
                        details.testInfo.testInfo.forEach((t, i) => {
                            tempTestInfoErrMsgArray.push('');
                        });
                        setTestInfoErrMsg(tempTestInfoErrMsgArray); // same length as testInfo array
                        setSampleTestAssignment(details.testInfo.sampleTestAssignment);

                        //Limit Info
                        setLimitGroupAssign(details.limitInfo);
                        let templimitGroupAssignErrMsgArray = [[]];
                        details.limitInfo[0].forEach((t, i) => {
                            templimitGroupAssignErrMsgArray[0].push('');
                        });
                        setLimitGroupAssignErrMsg(templimitGroupAssignErrMsgArray[0].length > 0 ? templimitGroupAssignErrMsgArray : [['']]);

                        setResponseMsg(null);
                        setResponseStatus(true);
                        setShowResponseMsg(false);
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message) {
                        setResponseMsg(JSON.stringify(err.response.data.message));
                    }
                    else {
                        setResponseMsg('Something went wrong, please contact IT Support.');
                    }
                    setResponseStatus(false);
                    setShowResponseMsg(true);
                });
        setLoading(false);
    }

    const getSampleTypeDetails = async (sampleTypeId) => {
        setLoading(true);
        setShowResponseMsg(false);
        setResponseStatus(null);
        setShowResponseMsg(null);
        await api.get('/lims/client-active-sampletype-details', { params: { sampleTypeRef: sampleTypeId } })
                .then(res => {
                    if(res.data.success) { 
                        let details = res.data.data;

                        // Reg Info
                        setNoOfSample(details.regInfo.noOfSample || 1);
                        setDueDate(new Date(details.regInfo.dueDate) || null);
                        setReportFormat(details.regInfo.reportFormat);
                        setLabGroup(details.regInfo.labGroup);
                        if(!selectedRow) {
                            setSampleName(details.regInfo.sampleName);

                            //Test Info
                            setTestInfo(details.testInfo.testInfo);
                            setSampleTestAssignment(details.testInfo.sampleTestAssignment);
                            let tempTestInfoErrMsgArray = [];
                            details.testInfo.testInfo.forEach((t, i) => { //Should not be empty array
                                tempTestInfoErrMsgArray.push('');
                            });
                            setTestInfoErrMsg(tempTestInfoErrMsgArray.length > 0 ? tempTestInfoErrMsgArray : ['']); // same length as testInfo array
                        }
                        else {
                            let refinedTestAssign = [];
                            details.testInfo.sampleTestAssignment.forEach((e, i) => {
                                refinedTestAssign.push([]);
                                if(e.length > 0) {
                                    e.forEach((o, j) => {
                                        let index = testInfo.findIndex(t => t.testRef == o);
                                        if(index !== -1) { refinedTestAssign[i].push(o); }
                                    });
                                }
                            });
                            setSampleTestAssignment(refinedTestAssign);
                        }

                        //Limit info
                        setLimitGroupAssign(details.limitGroupAssign);
                        let templimitGroupAssignErrMsgArray = [[]];
                        details.limitGroupAssign[0].forEach((t, i) => {
                            templimitGroupAssignErrMsgArray[0].push('');
                        });
                        setLimitGroupAssignErrMsg(templimitGroupAssignErrMsgArray[0].length > 0 ? templimitGroupAssignErrMsgArray : [['']]);
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message)
                    {
                        setResponseMsg(JSON.stringify(err.response.data.message));
                    }
                    else {
                        setResponseMsg('Something went wrong, please contact IT Support.');
                    }
                    setResponseStatus(false);
                    setShowResponseMsg(true);
                });
        setLoading(false);
    }

    const getProductCategoryTests = async () => {
        setLoading(true);
        setShowResponseMsg(false);
        setResponseStatus(null);
        setShowResponseMsg(null);
        await api.get('/lims/product-category-tests', { params: { productCategory: productCategory } })
                .then(res => {
                    if(res.data.success) { 
                        setProductCategoryTests(res.data.data);
                    }else {
                        setProductCategoryTests(null);
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message){
                        setResponseMsg(JSON.stringify(err.response.data.message));
                    }
                    else {
                        setResponseMsg('Something went wrong, please contact IT Support.');
                    }
                    setResponseStatus(false);
                    setShowResponseMsg(true);
                });
        setLoading(false);
    }

    const submitSampleSubmission = async (submissionStatus) => {
        let validationMsgArray = [];
        // Client info
        if(!clientRef) {
            validationMsgArray.push('* Client is required.');
        }
        if(!companyName) {
            validationMsgArray.push('* Company Name is required.');
        }
        if(!contactPerson) {
            validationMsgArray.push('* Contact Person is required.');
        }
        if(!email) {
            validationMsgArray.push('* Email is required.');
        }
        if(submitterEmail && submitterEmail.length == 0) {
            validationMsgArray.push('* Submitter Email is required.');
        }

        //Reg Info
        if(!sampleName) { //Job descrip
            validationMsgArray.push('* Job Description is required.');
        }
        if(!noOfSample) {
            validationMsgArray.push('* No. of Sample(s) is required.');
        }
        if(!productCategory) {
            validationMsgArray.push('* Product category is required.');
        }
        if(!selectedAdditionalInfo[0].value) {
            validationMsgArray.push('* Batch No. is required.');
        }
        if(!selectedAdditionalInfo[1].value) {
            validationMsgArray.push('* Storage requirement is required.');
        }
        if(!selectedAdditionalInfo[2].value) {
            validationMsgArray.push('* Quantity is required.');
        }
        if(!selectedAdditionalInfo[3].value) {
            validationMsgArray.push('* Weight/Units is required.');
        }
        if(!selectedAdditionalInfo[4].value) {
            validationMsgArray.push('* Modes of Packing is required.');
        }
        // Sample Info can be empty

        // Chain of Custody Details
        if(!receivedDateTime) {
            validationMsgArray.push('* Date/Time Received is required.');
        }
        if(!receivedBy) {
            validationMsgArray.push('* Received by is required.');
        }

        //Test Info
        if(!testInfo || testInfo.length === 0 || !testInfo[0].testRef) {
            validationMsgArray.push('* Please select a test.');
        }

        let emptySampleTestAssign = true;
        for(let i=0; i < sampleTestAssignment.length; i++) {
            if(sampleTestAssignment[i].length > 0) { emptySampleTestAssign = false; }
        }
        if(emptySampleTestAssign && selectedRow && selectedRow.Status === "Accepted") { validationMsgArray.push('* Please assign test to sample.'); }

        if(validationMsgArray.length > 0) {
            setResponseStatus(false);
            setResponseMsg(validationMsgArray.toString());
            setShowResponseMsg(true);
            if(responseMsgSectionRef.current) { responseMsgSectionRef.current.scrollIntoView(); }
            return;
        }

        //Assign limit can be empty

        let submissionFormData = {
            submissionId : selectedRow ? selectedRow.Id : null,
            clientDetails : {
                client : clientRef ? clientRef.toString() : null,
                companyName : companyName ? companyName.toString() : null,
                clientRef: clientReference ? clientReference.toString() : null,
                address : address ? address.toString() : null,
                phone : phone ? phone.toString() : null,
                quoteNo : quoteNo ? quoteNo.toString() : null,
                orderNo : orderNo ? orderNo.toString() : null,
                contactPerson : contactPerson ? contactPerson.toString() : null,
                email : email ? email.toString() : null,
                submitter : submitter ? submitter.toString() : null,
                submitterEmail : submitterEmail,
                invoiceTo : invoiceTo ? invoiceTo.toString() : null,
                reportToEmail : reportToEmail ? reportToEmail.toString() : null
            },
            regInfo : {
                caiqtestId : caiqtestId ? caiqtestId.toString() : null,
                jobDescription : sampleName ? sampleName.toString() : null,
                noOfSample : noOfSample ? noOfSample.toString() : null,
                regPriority : regPriority ? regPriority.toString() : null,
                regStatus : submissionStatus ? submissionStatus.toString() : null,
                dueDate : dueDate,
                reportFormat : reportFormat ? reportFormat.toString() : null,
                productCategory : productCategory ? productCategory.toString() : null,
                labGroup : labGroup ? labGroup.toString() : null,
                selectedAdditionalInfo : selectedAdditionalInfo,
                otherStorageRequirement: otherStorageRequirement ? otherStorageRequirement.toString() : null,
                prePackagedType: prePackagedType ? prePackagedType.toString() : null,
                currentURegRef: currentURegRef ? currentURegRef.toString() : null,
                manualEnteredLimit: manualEnteredLimit,
            },
            sampleInfo : {
                sampleDetails : sampleInfo,
            },
            chainOfCustodyDetails : {
                temperatureCondition : temperatureCondition ? temperatureCondition.toString() : null,
                receivedDateTime : receivedDateTime,
                receivedBy : receivedBy ? receivedBy.toString() : null,
                secondCheckedBy : secondCheckedBy ? secondCheckedBy.toString() : null,
                reportDate : reportDate,
                invoiceDate : invoiceDate,
                additionalComments : additionalComments ? additionalComments.toString() : null
            },
            testInfo : {
                testProcedInfo : testInfo,
                sampleTestAssignment : sampleTestAssignment
            },
            limitInfo : limitGroupAssign,
            sampleTypeInfo : sampleTypeId,
            noOfJob : noOfJob
        }

        setLoading(true);
        setResponseMsg(null);
        setResponseStatus(null);
        setShowResponseMsg(false);
        setUploadFile('');

        if(selectedRow) {
            if(selectedRow.Status === 'Accepted') {
                // SR Create New Reg
                await api.post('/lims/create-submission-reg', { 
                    submissionId : submissionFormData.submissionId,
                    clientDetails : submissionFormData.clientDetails, 
                    regInfo : submissionFormData.regInfo,
                    sampleInfo : submissionFormData.sampleInfo, 
                    chainOfCustodyDetails : submissionFormData.chainOfCustodyDetails,
                    testInfo : submissionFormData.testInfo,
                    limitInfo : submissionFormData.limitInfo,
                    sampleTypeInfo : submissionFormData.sampleTypeInfo,
                    noOfJob : submissionFormData.noOfJob
                })
                .then(res => {
                    if(res.data.success) { 
                        setRegTidRegRef(res.data.data)
                        setResponseMsg(res.data.message);
                        setResponseStatus(true);
                    };
                })
                .catch(err => {
                    if (err && err.response && err.response.data && err.response.data.message) {
                        setResponseMsg(JSON.stringify(err.response.data.message));
                    }
                    else {
                        
                        setResponseMsg('Something went wrong, please contact IT Support.');
                    }
                    setResponseStatus(false);
                })
                .finally(() => {
                    setShowResponseMsg(true);
                });
            }
            if(!selectedRow.RegTID && (selectedRow.Status !== 'Accepted')) {
                // SR accepts submission
                await api.post('/lims/accept-sample-submission', { 
                    submissionId : submissionFormData.submissionId,
                    clientDetails : submissionFormData.clientDetails, 
                    regInfo : submissionFormData.regInfo,
                    chainOfCustodyDetails : submissionFormData.chainOfCustodyDetails,
                    testInfo : submissionFormData.testInfo,
                })
                .then(res => {
                    if(res.data.success) { 
                        setSampleSubmissionList(res.data.data);
                        setShowSubmissionFormDialog(false);
                    };
                })
                .catch(err => {
                    if (err && err.response && err.response.data && err.response.data.message) {
                        setResponseMsg(JSON.stringify(err.response.data.message));
                    }
                    else {
                        
                        setResponseMsg('Something went wrong, please contact IT Support.');
                    }
                    setResponseStatus(false);
                    setShowResponseMsg(true);
                })
                .finally(() => {
                    
                });
            }
        }
        else {
            // Sample Receiption creates submission
            await api.post('/lims/create-sample-submission', { 
                    clientDetails : submissionFormData.clientDetails, 
                    regInfo : submissionFormData.regInfo,
                    chainOfCustodyDetails : submissionFormData.chainOfCustodyDetails,
                    testInfo : submissionFormData.testInfo,
                })
                .then(res => {
                    if(res.data.success) { 
                        setSampleSubmissionList(res.data.data);
                        setShowSubmissionFormDialog(false);
                    };
                })
                .catch(err => {
                    if (err && err.response && err.response.data && err.response.data.message) {
                        setResponseMsg(JSON.stringify(err.response.data.message));
                    }
                    else {
                        
                        setResponseMsg('Something went wrong, please contact IT Support.');
                    }
                    setResponseStatus(false);
                    setShowResponseMsg(true);
                })
                .finally(() => {
                    
                });
        }
        setLoading(false);
    }

    const readUploadCsvData = (e) => {
        if(e.target && e.target.files && e.target.files.length > 0) {
            setLoading(true);
            let fileTypeArray = e.target.files[0].type.split('/');
            let fileNameArray = e.target.files[0].name.split('.');

            // Check file type
            if(fileNameArray[1].toLowerCase() !== 'csv' || fileTypeArray[1].toLowerCase() !== 'csv') {
                setUploadFileErrMsg('* Please only upload CSV file.');
                setUploadFile('');
                return;
            }
            setUploadFile(e.target.value || '');

            const reader = new FileReader();
            reader.addEventListener("load", () => {
                // const headers = reader.result.slice(0, reader.result.indexOf('\r\n')).split(',');
                // const rows = reader.result.slice(reader.result.indexOf('\r\n')+1).split('\r\n');
                const allRows = reader.result.split('\r\n');
                let testColumnIndex = null;
                let unitStr = 'µg#mg#g#kg#j#kj#°T#% of Total ß-casein#µg/kg#ng/L#ug/kg#mg/kg#CFU/g#g/100g#µg/100g#mg/100g#g/ml#MPN/100g#%#µgDFE#/100g#mg/g#ml#/25g#mg/L#% Phenol Equivalent#KJ/100g#/32.5g#µg RE/100g#';

                // Check test column index
                allRows.some((row, i) => {
                    if(testColumnIndex) {return true; }
                    row.split(',').some((field, j) => {
                        // check if test field specified
                        if(field.replace(' ', '').toLowerCase() === 'test' || field.replace(' ', '').toLowerCase() === 'tests') { 
                            return testColumnIndex = j;
                        }
                        else {
                            // check if field existence in product tests
                            productCategoryTests.some((item, index) => {
                                if(item && field && field.length > 1 && item.TestDescrip && item.TestDescrip.replace(' ', '').toLowerCase().includes(field.replace(' ', '').toLowerCase())) {
                                    return testColumnIndex = j;
                                }
                            })
                        }
                        
                    });
                });

                if(testColumnIndex === null) {
                    // set error message
                    setUploadFileErrMsg('* Cannot find test column.');
                    return;
                }
                else {
                    // start reading file
                    let tempTestInfoArray = [];
                    let tempTestInfoErrArray = [];

                    allRows.forEach((row, i) => {
                        let field = row.split(',')[testColumnIndex];
                        let found = false;

                        if(field && field.replace(' ', '').toLowerCase() != 'test' && field.replace(' ', '').toLowerCase() != 'tests') { 
                            productCategoryTests.some((item, j) => {
                                if(item && item.TestDescrip && item.TestDescrip.toLowerCase().includes(field.toLowerCase())) {
                                    if (tempTestInfoArray.some(t => t.testRef === item.TestRef)) {
                                        tempTestInfoErrArray.push('* Test is duplicated.')
                                    }else {
                                        tempTestInfoErrArray.push('');
                                    }
                                    tempTestInfoArray.push({procedRef : item.ProcedRef,  testRef : item.TestRef,  methodRef : item.MethodRef, note : ''});
                                    found = true;
                                    return;
                                }
                            });
                            if(!found) {
                                let fieldArray = field.split(' ');
                                if(!unitStr.toLocaleLowerCase().includes(fieldArray[fieldArray.length-1].toLowerCase())) {
                                    tempTestInfoArray.push({procedRef : '', testRef : '',  methodRef : '', note : '', unit : ''});
                                    tempTestInfoErrArray.push(`* Test ${field} is not available for current product category in line ${i+1}`);
                                }
                            }
                        }
                    });

                    if(tempTestInfoArray.length === 0) { tempTestInfoArray.push({procedRef : '', testRef : '',  methodRef : '', note : '', unit : ''}); }
                    if(tempTestInfoErrArray.length === 0) { tempTestInfoErrArray.push(''); }

                    setTestInfo(tempTestInfoArray);
                    setTestInfoErrMsg(tempTestInfoErrArray);
                }
            });
            reader.readAsText(e.target.files[0]);
            setLoading(false);
        }
    }

    const setInputNoOfSample = (value) => {
        let validationResult = IntegerValidator(value);
        if(!validationResult || value <= 0) { value = 1; }
        setNoOfSample(value);
    }

    const setInputNoOfJob = (value) => {
        let validationResult = IntegerValidator(value);
        if(!validationResult || value <= 0) {
            setNoOfJob(1);
            return;
        }
        setNoOfJob(value);
    }

    const changeAdditionalSampleInfoColumn = (value, index) => {
        if(value && additionalSampleInfoColumn.includes(value)) { return; }
        let columnArray = [...additionalSampleInfoColumn];
        columnArray[index] = value;
        if(value) {
            if(index + 1 === columnArray.length)
            {
                columnArray.push('');
            }

            let tempSampleInfo = [...sampleInfo];
            tempSampleInfo.forEach(info => {    
                let tempValue = null; 
                if(info.hasOwnProperty(additionalSampleInfoColumn[index])){
                    let attr = additionalSampleInfoColumn[index];
                    tempValue = info[attr];
                    delete info[attr];
                }
                info[value] = tempValue;
             })
             setSampleInfo(tempSampleInfo);
        }
        else {
            if(index + 1 < columnArray.length) {
                columnArray.splice(index, 1)
            }

            let tempSampleInfo = [...sampleInfo];
            tempSampleInfo.forEach(info => {    
                if(info.hasOwnProperty(additionalSampleInfoColumn[index])){
                    let attr = additionalSampleInfoColumn[index];
                    delete info[attr];
                }
             })
             setSampleInfo(tempSampleInfo);
        }
        setAdditionalSampleInfoColumn(columnArray);
    }

    const setSampleInfoTableData = (value, index, column) => {
        setSampleInfo((prevItems) =>
            prevItems.map((row, i) =>
              i === index ? { ...row, [column]: value } : row
            )
        );
    }

    const setSampleInfoTableColumnCopyDown = (value, rowIndex, column) => {
        setSampleInfo((prevItems) =>
            prevItems.map((row, i) =>
              i >= rowIndex ? { ...row, [column]: value } : row
            )
        );
    }

    const setSampleTestAssignmentData = (tRef, index) => {
        let tempArray = [...sampleTestAssignment];
        let row = tempArray[index];
        if(!row.includes(tRef)) { row.push(tRef); }
        else { 
            let i = row.indexOf(tRef);
            row.splice(i, 1);
        }
        setSampleTestAssignment(tempArray);
    }

    const setSampleTestAssignmentColumnCopyDown = (index) => {
        if(productCategoryTests === null) return;
        if(!testInfo[0].testRef) return;
        let tempArray = [...sampleTestAssignment];
        if(tempArray[index].length < testInfo.length) {
            testInfo.forEach((test) =>{
                if(!tempArray[index].includes(test.testRef)) {
                    tempArray[index].push(test.testRef);
                }
            });
        }
        else {
            tempArray[index] = [];
        }
        setSampleTestAssignment(tempArray);
    }

    const setGlobalLimitAssignmentData = (index, value, type) => {
        let tempArray = [...limitGroupAssign];
        let globalLimitTempArray = tempArray[0];

        switch(type) {
            case 'descrip':
                let tempErrMsg = [...limitGroupAssignErrMsg];
                let globalErrMsg = tempErrMsg[0];
                globalErrMsg[index] = tempArray[0].filter(l => l.id === value).length > 0 ? '* Limit is duplicated.' : '';
                setLimitGroupAssignErrMsg(tempErrMsg);

                if(value) {
                    let limitGroupObj = clientLimitGroupList.filter(l => l.ID === value)[0];
                    globalLimitTempArray[index].id = limitGroupObj ? limitGroupObj.ID : null;
                    globalLimitTempArray[index].descrip = limitGroupObj ? limitGroupObj.DESCRIPTION : null;
                    globalLimitTempArray[index].limitType = limitGroupObj ? limitGroupObj.LIMITTYPE : null;
                    globalLimitTempArray[index].priority = limitGroupObj ? limitGroupObj.PRIORITY : null;
                }
                else {
                    globalLimitTempArray[index].id = '';
                    globalLimitTempArray[index].limitType = '';
                    globalLimitTempArray[index].descrip = '';
                    globalLimitTempArray[index].priority = '';
                }
                break;
            case 'priority':
                if(value < 1 || !IntegerValidator(value)) { value = 1; }
                globalLimitTempArray[index].priority = value;
                break;
        }
        setLimitGroupAssign(tempArray);
    }

    const clearSampleTypeData = () => {
        // Sample Information
        setSampleName(null);
        setProductCategory(null);

        // Report Information
        setNoOfJob(1);

        // Test Information
        setTestInfo([{procedRef : '', testRef : '', methodRef : '', note : '', unit : ''}]);
    }

    const clearSampleSubmissionData = () => {
        // Client Details
        setClientRef();
        setClientReference(null);
        setAddress(null);
        setPhone(null);
        setQuoteNo(null);
        setOrderNo(null);
        setContactPerson(null);
        setEmail(null);
        setSubmitter(null);
        setSubmitterEmail([]);
        setInvoiceTo(null);
        setAdditionalComments(null);

        // Sample Information
        setSampleName(null);
        setProductCategory(null);

        // Report Information
        setNoOfJob(1);

        // Test Information
        setTestInfo([{procedRef : '', testRef : '', methodRef : '', note : '', unit : ''}]);
    }

    const getClientList = async () => {
        setLoading(true);
        await api.get('/lims/active-clients')
                .then(res => {
                    if(res.data.success) { 
                        setClientList(res.data.data);
                    }else {
                        setClientList(null);
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message) {
                        setResponseMsg(JSON.stringify(err.response.data.message));
                    }
                    else{
                        setResponseMsg('Something went wrong, please contact IT Support.');
                    }
                    setResponseStatus(false);
                    setShowResponseMsg(true);
                });
        setLoading(false);
    }

    const getClientContactDetails = async () => {
        setLoading(true);
        setShowResponseMsg(false);
        setResponseStatus(null);
        setShowResponseMsg(null);
        await api.get('/lims/active-client-contact', { params: { clientRef: clientRef } })
                .then(res => {
                    if(res.data.success) { 
                        setMainContact(res.data.data.mainContact);
                        setContactCcList(res.data.data.contactCcList);
                    }else {
                        setMainContact(res.data.data.mainContact);
                        setContactCcList(res.data.data.contactCcList);
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message) {
                        setResponseMsg(JSON.stringify(err.response.data.message));
                    }
                    else{
                        setResponseMsg('Something went wrong, please contact IT Support.');
                    }
                    setResponseStatus(false);
                    setShowResponseMsg(true);
                });
        setLoading(false);
    }

    const getClientSampleTypeList = async () => {
        setLoading(true);
        await api.get('/lims/client-active-sampletypes', { params: { clientRef: clientRef } })
                .then(res => {
                    if(res.data.success) { 
                        setSampleTypeList(res.data.data);
                    }else {
                        setSampleTypeList(null);
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message) {
                        console.log(err.response.data.message);
                    }
                });
        setLoading(false);
    }

    const getClientRecentSubmissionList = async () => {
        setLoading(true);
        await api.get('/lims/client-recent-submissions', { params: { clientRef: clientRef } })
                .then(res => {
                    if(res.data.success) { 
                        setRecentSubmissionList(res.data.data);
                    }else {
                        setRecentSubmissionList(null);
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message) {
                        console.log(err.response.data.message);
                    }
                });
        setLoading(false);
    }

    const getClientLimitGroupList = async () => {
        setLoading(true);
        await api.get('/lims/client-valid-limitgroups', { params: { clientRef: clientRef } })
                .then(res => {
                    if(res.data.success) { 
                        setClientLimitGroupList(res.data.data);
                    }else {
                        setClientLimitGroupList([]);
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message) {
                        console.log(err.response.data.message);
                    }
                });
        setLoading(false);
    }

    const getClientLastSampleSubmissionClientDetails = async () => {
        setLoading(true);
        await api.get('/lims/client-last-sample-submission-client-details', { params: { clientRef: clientRef } })
                .then(res => {
                    if(res.data.success) { 
                        let details = res.data.data;
                        setCompanyName(details.COMPANY_NAME);
                        setClientReference(details.CLIENT_REF);
                        setAddress(details.CL_ADDRESS);
                        setPhone(details.CL_PHONE);
                        setQuoteNo(details.QUOTE);
                        setOrderNo(details.ORDER);
                        setContactPerson(details.CONTACT_PERSON);
                        setEmail(details.CONTACT_EMAIL);
                        setSubmitter(details.SUBMITTER);
                        setSubmitterEmail(details.SUB_EMAIL);
                        setInvoiceTo(details.INV_TO);
                        setReportToEmail(details.RPTTO_EMAIL);
                        setAdditionalComments('');
                        setResponseStatus(true);
                        setShowResponseMsg(false);
                    }
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message) {
                        setResponseMsg(JSON.stringify(err.response.data.message));
                    }
                    else{
                        setResponseMsg('Something went wrong, please contact IT Support.');
                    }
                    setResponseStatus(false);
                    setShowResponseMsg(true);
                });
        setLoading(false);
    }

    const getSampleSubmissionKeyWords = async () => {
        setLoading(true);
        await api.get('/lims/submissions-key-words')
                .then(res => {
                    if(res.data.success) {
                        setKeyWordList(res.data.data);
                    }else {
                        setKeyWordList(null);
                    };
                })
                .catch(err => {
                    if(err && err.response && err.response.data && err.response.data.message) {
                        console.log(err.response.data.message);
                    }
                });
        setLoading(false);
    }

    const CustomURefRefField = () => {
        const [showURegRefTooltip, setShowURegRefTooltip] = useState(false);

        const buildURegRefTooltipText = () => {
            return (
                recentURegRefs.map((regRef, index) => (
                    <div key={index}>{regRef}</div>
                ))
            )
        }

        return (
            <div className="col-span-3 mx-2">
                <div className="flex">
                    <label 
                        className="block text-md font-medium text-gray-700 mt-1 cursor-pointer"
                        onClick={ () => setShowCurrentURegRef(!showCurrentURegRef) }
                    >
                        CAIQTEST Pacific Unique ID
                    </label>
                    <div className="w-1/2">
                        <input
                            value={currentURegRef || ''}
                            onChange={e => { setCurrentURegRef(e.target.value) }}
                            onMouseEnter={() => setShowURegRefTooltip(true && currentURegRef)} 
                            onMouseLeave={() => setShowURegRefTooltip(false)}
                            type="text"
                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                        />
                        
                    </div>
                    {showURegRefTooltip && <div className="min-w-110 group-hover:opacity-100 transition-opacity bg-gray-800 px-1 py-1 text-xs text-gray-100 rounded-md absolute left-1/3 top-1/3 -translate-x-1/2 translate-y-full m-4 mx-auto text-left z-10">
                            {buildURegRefTooltipText()}
                    </div>}
                </div>
            </div>
        );
    }

    useEffect(() => {
        getClientList();
        getSampleSubmissionKeyWords();
        getProductCategoryTests();
        if(selectedRow) { 
            //getSampleSubmissionDetails(selectedRow.Id); //avoid duplicate call
            if(clientRef) { getClientLimitGroupList(); }
        }
    }, []);

    useEffect(() => {
        // client selected during create new submission
        if(clientRef) {
            if(!selectedRow) { 
                getClientRecentSubmissionList();
            }
            getClientSampleTypeList();
            getClientLimitGroupList();
            getClientContactDetails();
        }
    }, [clientRef]);

    useEffect(() => {
        if(selectedRow) { getSampleSubmissionDetails(selectedRow.Id); }
    }, [caiqtestId]);

    useEffect(() => {
        if(submissionTemplateId) { getSampleSubmissionDetails(submissionTemplateId); }
        else { clearSampleSubmissionData(); }
    }, [submissionTemplateId]);

    useEffect(() => {
        if(sampleTypeId) { getSampleTypeDetails(sampleTypeId); }
        else { clearSampleTypeData(); }
    }, [sampleTypeId]);

    useEffect(() => {
        if(noOfSample > sampleInfo.length) { 
            let emptyObj = {SAMPLE_ID : '',  SAMPLE_INFO : '', SAMPLE_TIME : null, DUE_DATE : ''};

            additionalSampleInfoColumn.forEach((item) => { if(item && !emptyObj.hasOwnProperty(item)){ emptyObj[item] = null; } })

            for(let i = 0; i < noOfSample - sampleInfo.length; i++) { setSampleInfo((prevItems) => [...prevItems, emptyObj]); }
        }
        else if(noOfSample < sampleInfo.length) { 
            setSampleInfo((prevItems) => prevItems.filter((row, i) => i < noOfSample));
        }

        if(noOfSample > sampleTestAssignment.length) {
            for(let i = 0; i < noOfSample - sampleTestAssignment.length; i++) { setSampleTestAssignment((prevItems) => [...prevItems, []]); }
        }
        else if(noOfSample < sampleTestAssignment.length) {
            setSampleTestAssignment((prevItems) => prevItems.filter((row, i) => i < noOfSample));
        }
    }, [noOfSample]);

    useEffect(() => {
        if(responseMsgSectionRef.current) { responseMsgSectionRef.current.scrollIntoView(); }
    }, [responseStatus, responseMsg, showResponseMsg]);

    return (
        <div className="w-screen h-screen fixed left-0 top-0 bg-gray-800 bg-opacity-80 z-20 flex items-center">
            <div className="w-4/5 mx-auto">
                <div className="py-5 px-16 flex justify-between bg-gray-100 rounded-t-lg">
                    <div className="flex text-gray-900 text-left text-sm w-1/3 mt-3 -mb-3">
                        {((selectedRow && selectedRow.Status=='Accepted') || !selectedRow) && 
                        <>
                        <label className="block text-md font-medium text-gray-700 mt-1">Sample Type</label>
                        <select className="ml-3 py-1 px-4 block w-2/3 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                            value={ sampleTypeId || ''}
                            disabled={!clientRef}
                            onChange={e => { setSampleTypeId(e.target.value) }}
                        >
                            <option className="text-gray-400" value=''>None</option>
                            {sampleTypeList && sampleTypeList.length > 0 &&
                                sampleTypeList.map((sampleType, index) => (
                                    <option key={index} className="text-gray-800" value={sampleType.Ref}>{sampleType.Descrip}</option>
                            ))} 
                        </select>
                        </>
                        }
                    </div>

                    <div className="text-gray-900 text-center font-semibold w-1/3">
                        Sample Submission Form
                    </div>
                    
                    {!selectedRow && <div className="flex text-gray-900 text-right text-sm w-1/3 mt-3 -mb-3">
                        <label className="block text-md font-medium text-gray-700 mt-1 ml-5">Submission</label>
                        <select className="ml-3 py-1 px-4 block w-2/3 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                            value={ submissionTemplateId || ''}
                            disabled={!clientRef}
                            onChange={e => { setSubmissionTemplateId(e.target.value) }}
                        >
                            <option className="text-gray-400" value=''>None</option>
                            {recentSubmissionList && recentSubmissionList.length > 0 &&
                                recentSubmissionList.map((sub, index) => (
                                    <option key={index} className="text-gray-800" value={sub.Id}>{sub.SampleName}</option>
                                ))} 
                        </select>
                    </div>}
                    {selectedRow && <div className="flex text-gray-900 justify-end text-sm w-1/3 mt-3 -mb-3">
                        <div className="px-4 py-1 text-gray-500">
                            <button className="focus:outline-none" disabled={loading} onClick={()=>getSampleSubmissionDetails(selectedRow.Id)}>
                                { loading ? getSpinnerIcon() : getRefreshIcon() }
                            </button>
                        </div>
                    </div>}
                </div>
                
                <div className="shadow bg-white max-h-630 h-630">
                    <div className="w-full">
                    {/* dialog nav */}
                    <div className="px-10 pt-2">
                        <div className="border-b-2 border-gray-200">
                            <div className="-mb-px flex justify-between">
                                {submissionNavItem.map((item, index) =>(
                                    <div onClick={() => setCurrentTab(item.value) } key={index} className={`${currentTab === item.value ? "text-purple-600 border-b-2 border-purple-500 " : "text-gray-900"} whitespace-nowrap py-4 px-1 font-medium text-md cursor-pointer`}>{item.label}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* dialog content */}
                    <div className="px-10 w-full ">          
                        {currentTab === 'Client Details' &&
                        <div className="pb-5 grid grid-cols-6 gap-y-6 gap-x-4 max-h-563 h-563 overflow-y-auto">
                            <div className="col-span-6">
                                {showResponseMsg && <ResponseMessageField />}
                            </div>
                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Client
                                    </label>
                                    <div className="w-4/5">
                                        <div className='flex'>
                                            <select className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                value={clientRef || ''}
                                                disabled={selectedRow}
                                                onChange={e => { setInputClientRef(e.target.value) }}>
                                                <option value=""></option>
                                                {clientList && clientList.map((client, index) =>(
                                                    <option className="text-gray-800" key={index} value={client.ClRef}>{client.Client}</option>
                                                ))}
                                            </select>
                                            {clientRef && !selectedRow &&
                                                <div className='mx-2 ml-2 mt-2 text-sm text-indigo-600 hover:text-indigo-900 cursor-pointer'
                                                    onClick={ () => { getClientLastSampleSubmissionClientDetails(); } }
                                                    >
                                                        {loading ? getSpinnerIcon() : 'Fill'}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {!clientRef && <p className="text-red-500 text-xs italic mt-2">* Client is required.</p>}
                            </div>

                            <div className="col-span-3 mx-2">
                                <div className="flex col-span-3 mx-2">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Company Name
                                    </label>
                                    <div className="w-2/3">
                                        <input
                                            value={companyName || ''}
                                            onChange={e => { setCompanyName(e.target.value); }}
                                            type="text"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>
                                </div>
                                {!companyName && <p className="text-red-500 text-xs italic mt-2">* Company Name is required.</p>}
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Client Reference
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={clientReference || ''}
                                        onChange={e => { setClientReference(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Phone
                                </label>
                                <div className="w-3/4">
                                    <input
                                        value={phone || ''}
                                        onChange={e => { setPhone(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-6 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Address
                                </label>
                                <div className="w-5/6">
                                    <input
                                        value={address || ''}
                                        onChange={e => { setAddress(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Quote #
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={quoteNo || ''}
                                        onChange={e => { setQuoteNo(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Order #
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={orderNo || ''}
                                        onChange={e => { setOrderNo(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                           
                            <div className="col-span-3 mx-2">
                                {/* Contact Person */}
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Contact Person
                                    </label>
                                    <div className="w-1/2">
                                        <textarea
                                            rows={4}
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            value={contactPerson || ""}
                                            onChange={e => { setContactPerson(e.target.value); }}
                                        />
                                    </div>
                                </div>
                                {!contactPerson && <p className="text-red-500 text-xs italic mt-2">* Contact person is required.</p>}
                            </div>
                            

                            <div className="col-span-3 mx-2">
                                {/* Email */}
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Email
                                    </label>
                                    <div className="w-2/3">
                                        <textarea
                                            rows={4}
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            value={email || ''}
                                            onChange={e => {setEmail(e.target.value)}}
                                        />
                                    </div>
                                </div>
                                {!email&& <p className="text-red-500 text-xs italic mt-2">* Email is required.</p>}
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Submitter (if not client)
                                </label>
                                <div className="w-5/12">
                                    <input
                                        value={submitter || ''}
                                        onChange={e => { setSubmitter(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Invoice To (if not client)
                                </label>
                                <div className="w-5/12">
                                    <input
                                        value={invoiceTo || ''}
                                        onChange={e => { setInvoiceTo(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Submitter Email
                                    </label>
                                    <div className="w-2/3 ml-3">
                                        <ReactMultiEmail
                                            emails={submitterEmail}
                                            onChange={_emails => { setSubmitterEmail(_emails); }}
                                            getLabel={(email, index, removeEmail) => {
                                                return (
                                                  <div data-tag key={index}>
                                                    <div data-tag-item>{email}</div>
                                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                                      ×
                                                    </span>
                                                  </div>
                                                );
                                              }}
                                        />
                                    </div>
                                </div>
                                {submitterEmail && submitterEmail.length == 0 && <p className="text-red-500 text-xs italic mt-2">* Submitter email is required.</p>}
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    E-mail Report to
                                </label>
                                <div className="w-1/2">
                                    <input
                                        value={reportToEmail || ''}
                                        onChange={e => { setReportToEmail(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>
                        </div>}

                        {currentTab === "Registration Information" &&
                        <div className="pb-3 grid grid-cols-6 gap-y-6 gap-x-4 max-h-563 h-563 overflow-y-auto">
                            <div className="col-span-6">
                                {showResponseMsg && <ResponseMessageField />}
                            </div>
                            <div className="col-span-6 -mt-2">
                                <div className='w-1/3 border-purple-500 text-gray-900 text-base leading-6 font-medium py-2 px-1 border-b-2'>Enssential Information</div>
                            </div>

                            {selectedRow && selectedRow.Status === "Accepted" && !showCurrentURegRef &&
                            <div className="flex col-span-3 mx-2">
                                <label 
                                    className="block text-md font-medium text-gray-700 mt-1 cursor-pointer"
                                    onClick={ () => setShowCurrentURegRef(!showCurrentURegRef) }
                                >
                                    CAIQTEST Pacific Unique ID
                                </label>
                                <div className="w-1/2">
                                    <select className={`${!selectedRow ? 'bg-gray-100' : ''} ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md`}
                                        value={caiqtestId || ''}
                                        disabled={!selectedRow && !regTidRegRef}
                                        onChange={e => { setCaiqtestId(e.target.value) }}>
                                        <option key={'0'} value={null}></option>
                                        {regTidRegRef && Object.keys(regTidRegRef).length > 0 && Object.keys(regTidRegRef).map((key, i) =>(<option key={i} value={key}>{regTidRegRef[key]}</option>))}
                                    </select>
                                </div>
                            </div>}

                            {selectedRow && selectedRow.Status === "Accepted" && showCurrentURegRef && <CustomURefRefField />}

                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        {selectedRow && selectedRow.Status === "Accepted" ? 'Job Description' : 'Sample Name'}
                                    </label>
                                    <div className="w-2/3">
                                        <input
                                            value={sampleName || ''}
                                            onChange={e => { setSampleName(e.target.value) }}
                                            type="text"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>
                                </div>
                                {!sampleName && <p className="text-red-500 text-xs italic mt-2">* {selectedRow && selectedRow.Status === "Accepted" ? 'Job Description' : 'Sample Name'} is required.</p>}
                            </div>

                            {selectedRow && selectedRow.Status === "Accepted" &&
                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    No. of Sample(s)
                                </label>
                                <div className="w-2/5">
                                    <input
                                        value={noOfSample || 1}
                                        onChange={e => { setInputNoOfSample(e.target.value) }}
                                        type="number"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>}

                            {selectedRow && selectedRow.Status === "Accepted" &&
                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Priority
                                </label>
                                <div className="w-5/12">
                                    <select className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        value={regPriority || ''}
                                        onChange={e => { setRegPriority(e.target.value) }}>
                                        <option value="0">Normal</option>
                                        <option value="1">Priority</option>
                                        <option value="2">Urgent</option>
                                    </select>
                                </div>
                            </div>}

                            {selectedRow && 
                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Status
                                </label>
                                <div className="w-1/2">
                                    <select className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        value={regStatus }
                                        disabled={true}
                                        onChange={e => { setRegStatus(e.target.value) }}>
                                        <option value='1'>Pending</option>
                                        <option value='2'>Incomplete</option>
                                        <option value='3'>Accepted</option>
                                        <option value='4'>On Hold</option>
                                        <option value='5'>Cancelled</option>
                                    </select>
                                </div>
                            </div>}

                            {selectedRow && selectedRow.Status === "Accepted" &&
                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-2">
                                        Due Date
                                    </label>
                                    <div className="w-4/5">
                                        <DatePicker selected={dueDate} onChange={date => {setDueDate(date)}} customInput={<DueDatePicker />} dateFormat="dd/MMM/yyyy"/>
                                    </div>
                                </div>
                            </div>}

                            {selectedRow && selectedRow.Status === "Accepted" &&
                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Report Format
                                </label>
                                <div className="w-1/2">
                                    <select className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        value={reportFormat || ''}
                                        onChange={e => { setReportFormat(e.target.value) }}>
                                        <option value="IANZ_REPORT">IANZ</option>
                                        <option value="IANZ_RPT_rtf">IANZ RTF</option>
                                        <option value="GENERAL_REPORT">General Report</option>
                                        <option value="GEN_REPORT_RTF">General Report RTF</option>
                                        <option value="CHN_GENERA_CNAS">CNAS-CHN</option>
                                        <option value="CHN_GENERAL_RPT">CNAS-CHN RTF</option>
                                    </select>
                                </div>
                            </div>}

                            {selectedRow && selectedRow.Status === "Accepted" &&
                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Lab Group
                                </label>
                                <div className="w-1/2">
                                    <select className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        value={labGroup || ''}
                                        onChange={e => { setLabGroup(e.target.value) }}>
                                        <option value="CHEMISTRY">Chemistry</option>
                                        <option value="MICROBIOLOGY">Microbiology</option>
                                        <option value="COMBINE">Chemistry & Microbiology</option>
                                    </select>
                                </div>
                            </div>}

                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Product Category
                                    </label>
                                    <div className="w-1/2">
                                        <select className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            value={productCategory || ''}
                                            onChange={e => { setProductCategory(e.target.value) }}>
                                            <option value=""></option>
                                            <option value="1">Infant Formula Stage 1</option>
                                            <option value="2">Infant Formula Stage 2</option>
                                            <option value="3">Infant Formula Stage 3</option>
                                            <option value="4">Infant Formula Child (Stage 4)</option>
                                            <option value="5">Milk Power</option>
                                            <option value="6">Liquid Milk</option>
                                            <option value="7">Whey Powder</option>
                                            <option value="8">Cheese</option>
                                            <option value="9">Honey</option>
                                            <option value="10">Yoghurt</option>
                                            <option value="11">Cream</option>
                                            <option value="12">Base Powder</option>
                                            <option value="13">Pet Food</option>
                                            <option value="14">UHT Milk</option>
                                            <option value="0">Other</option>
                                        </select>
                                    </div>
                                </div>
                                {!productCategory && <p className="text-red-500 text-xs italic mt-2">* Product category is required.</p>}
                            </div>

                            <div className="col-span-6">
                                <div className='w-1/3 border-purple-500 text-gray-900 text-base leading-6 font-medium py-2 px-1 border-b-2'>Additional Information</div>
                            </div>

                            {selectedAdditionalInfo && selectedAdditionalInfo.length > 0 && 
                                selectedAdditionalInfo.map((field, i) =>(
                                    <div className="col-span-3 mx-2" key={i}>
                                        <div className="flex">
                                            <div className="mt-1 cursor-pointer"
                                                onClick={() => spliceFromSelectedAdditionalInfoArray(i) }
                                            >
                                                {getMinusIcon()}
                                            </div>
                                            <div className="w-2/5 mx-2">
                                                <select className={`${!field.field ? 'text-gray-400' : ''} ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md`}
                                                    value={field.field || ''}
                                                    onChange={(e) =>{ setInputSelectedAdditionalInfo(i, e.target.value, '', 'Field') }}
                                                    disabled={ selectedRow && selectedRow.Status === "Accepted" ? i < 7 : i < 5 }
                                                >
                                                    <option className="text-gray-300" value="">Field Name</option>
                                                    {keyWordList && keyWordList.length > 0 &&
                                                        keyWordList.map((item, index)=>(
                                                            <option key={index} className="text-gray-800" value={item.KWRef}>{item.KWDes}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                            <div className="w-3/5 mx-2 flex">
                                                {field.field != 'STOR_REQUIRE' && field.field != 'QUANTITY' && field.field != 'WEIGHT_UNIT' && field.field != 'PACK_MODE' && field.field != 'TRANSITIONAL' && field.field != 'INI_IMPORT' &&
                                                    field.field != 'SAMPLE_FORM' &&
                                                <input
                                                    value={field.value || ''}
                                                    onChange={(e) =>{ setInputSelectedAdditionalInfo(i, field.field, e.target.value, 'Value') }}
                                                    disabled={!field.field}
                                                    type="text"
                                                    className="ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                />}
                                                {/* Storage Requirement */}
                                                {field.field === 'STOR_REQUIRE' && field.value != 'Other' &&
                                                <select className="ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                    value={field.value || ''}
                                                    onChange={e => { setInputSelectedAdditionalInfo(i, field.field, e.target.value, 'Value') }}>
                                                    <option value=""></option>
                                                    <option value="Room Temperature">Room Temperature</option>
                                                    <option value="Cold Storage">Cold Storage</option>
                                                    <option value="Freeze">Freeze</option>
                                                    <option value="Other">Other</option>
                                                </select>}
                                                {field.field === 'STOR_REQUIRE' && field.value === 'Other' &&
                                                <input
                                                    value={otherStorageRequirement || ''}
                                                    onChange={(e) =>{ setInputOtherStorageRequirement(e.target.value) }}
                                                    disabled={!field.field}
                                                    type="text"
                                                    className="ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                />}
                                                {/* Quantity */}
                                                {field.field === 'QUANTITY' &&
                                                <textarea
                                                    rows={2}
                                                    className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                    value={field.value || ''}
                                                    onChange={(e) =>{ setInputQuantity(e.target.value) }}
                                                    disabled={!field.field}
                                                />}
                                                {/* Weight unit */}
                                                {field.field === 'WEIGHT_UNIT' &&
                                                <textarea
                                                    rows={2}
                                                    className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                    value={field.value || ''}
                                                    onChange={e => { setInputWeightUnit(e.target.value); }}
                                                    disabled={!field.field}
                                                />}        
                               
                                                {/* Modes of Packing */}
                                                {field.field === 'PACK_MODE' && field.value !== 'Pre-Packed' &&
                                                <select className="ml-2 py-1 px-4 w-full block shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                    value={field.value || ''}
                                                    onChange={e => { setInputPackingMode(e.target.value); }}>
                                                    <option value=""></option>
                                                    <option value="Pre-Packed">Pre-Packed</option>
                                                    <option value="Bulk">Bulk</option>
                                                </select>}
                                                {field.field === 'PACK_MODE' && field.value === 'Pre-Packed' &&
                                                <select className="ml-2 py-1 px-4 w-full block shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                    value={prePackagedType || ''}
                                                    onChange={e => { setInputPrePackagedType(e.target.value); }}>
                                                    <option value=""></option>
                                                    <option value="Sealed">Sealed</option>
                                                    <option value="Unsealed">Unsealed</option>
                                                </select>}
                                                {/* Yes/No field */}
                                                {(field.field === 'TRANSITIONAL' || field.field === 'INI_IMPORT') &&
                                                <select className="ml-2 py-1 px-4 w-full block shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                    value={field.value || ''}
                                                    onChange={e => { setInputSelectedAdditionalInfo(i, field.field, e.target.value, 'Value') }}>
                                                    <option value=""></option>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </select>}
                                                {/* Sample Form */}
                                                {field.field === 'SAMPLE_FORM' &&
                                                <select className="ml-2 py-1 px-4 w-full block shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                    value={field.value || ''}
                                                    onChange={e => { setInputSelectedAdditionalInfo(i, field.field, e.target.value, 'Value') }}>
                                                    <option value=""></option>
                                                    <option value="Powder">Powder</option>
                                                    <option value="Liquid">Liquid</option>
                                                    <option value="Solid">Solid</option>
                                                </select>}
                                            </div>
                                            <div className="ml-4 mt-1 cursor-pointer"
                                                onClick={() => pushIntoSelectedAdditionalInfoArray() }
                                            >
                                                {getPlusIcon()}
                                            </div>
                                        </div>
                                    </div>
                            ))}
                            <div ref={regTabBottomRef} className={`${selectedAdditionalInfo && selectedAdditionalInfo.length % 2 === 0 ? 'mt-8' : ''}`}></div>
                        </div>}
                        
                        {currentTab === "Sample's Information" &&
                        <div className="py-1 grid grid-cols-1 gap-y-6 gap-x-4">
                            <div className="flex flex-col col-span-6">
                                <div className="-my-2 -mx-8">
                                    <div className="py-2 px-8 align-middle inline-block w-full">
                                        {showResponseMsg && <div className="-mt-1 mb-5 col-span-6">
                                            <ResponseMessageField />
                                        </div>}
                                        <div className="mt-3 h-525 shadow overflow-x-auto overflow-y-auto border-b border-gray-200 rounded-lg">
                                            <table className="min-w-full divide-y divide-gray-200">
                                                <thead className="bg-gray-50">
                                                    <tr className="text-center">
                                                        <th className="px-2 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                            Sample No.
                                                        </th>
                                                        <th className="px-2 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                            Sample ID
                                                        </th>
                                                        <th className="px-2 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                            Sample Info
                                                        </th>
                                                        <th className="px-2 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                            Sample Time
                                                        </th>
                                                        <th className="px-2 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                            Due Date
                                                        </th>
                                                        {additionalSampleInfoColumn && additionalSampleInfoColumn.length > 0 &&
                                                            additionalSampleInfoColumn.map((column, i) =>(
                                                                <th key={i} className="px-1 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                                    <select 
                                                                        value={column || ''}
                                                                        onChange={e => { changeAdditionalSampleInfoColumn(e.target.value, i)}}
                                                                        className="w-138 py-1 px-4 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                                    >
                                                                        <option className="text-gray-300" value="">Add Column</option>
                                                                        {keyWordList && keyWordList.length > 0 &&
                                                                            keyWordList.map((item, j)=>(
                                                                                <option key={j} className="text-gray-800" value={item.KWRef}>{item.KWDes}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </th>))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sampleInfo && sampleInfo.length > 0 &&
                                                        sampleInfo.map((row, index) =>(
                                                            <tr key={index} className="border-b border-gray-200">
                                                                <td className="px-2 py-3 whitespace-nowrap text-sm text-center text-gray-900">{index + 1}</td>
                                                                
                                                                <td className="px-2 py-3 whitespace-nowrap text-sm text-center text-gray-500">
                                                                    <div className="flex">
                                                                        <input className="py-1 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md text-center"
                                                                            value={row.SAMPLE_ID || ''}
                                                                            onChange={(e) => setSampleInfoTableData(e.target.value, index, 'SAMPLE_ID')}
                                                                        />
                                                                        {row && row.SAMPLE_ID && <div className="-ml-7 mt-1 cursor-pointer z-10" onClick={(e)=> {setSampleInfoTableColumnCopyDown(row.SAMPLE_ID, index, 'SAMPLE_ID'); e.stopPropagation();}}>{getChevronDownIcon()}</div>}
                                                                    </div>
                                                                </td>

                                                                <td className="px-2 py-3 whitespace-nowrap text-sm text-center text-gray-500">
                                                                    <div className="flex">
                                                                        <input className="py-1 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md text-center"
                                                                            value={row.SAMPLE_INFO || ''}
                                                                            onChange={(e) => setSampleInfoTableData(e.target.value, index, 'SAMPLE_INFO')}
                                                                        />
                                                                        {row && row.SAMPLE_INFO && <div className="-ml-7 mt-1 cursor-pointer z-10" onClick={(e)=> {setSampleInfoTableColumnCopyDown(row.SAMPLE_INFO, index, 'SAMPLE_INFO'); e.stopPropagation();}}>{getChevronDownIcon()}</div>}
                                                                    </div>
                                                                </td>

                                                                <td className="px-2 py-3 whitespace-nowrap text-sm text-center text-gray-500">
                                                                    <div className="flex">
                                                                        <input className="py-1 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md text-center"
                                                                            value={row.SAMPLE_TIME || ''}
                                                                            onChange={(e) => setSampleInfoTableData(e.target.value, index, 'SAMPLE_TIME')}
                                                                        />
                                                                        {row && row.SAMPLE_TIME && <div className="-ml-7 mt-1 cursor-pointer z-10" onClick={(e)=> {setSampleInfoTableColumnCopyDown(row.SAMPLE_TIME, index, 'SAMPLE_TIME'); e.stopPropagation();}}>{getChevronDownIcon()}</div>}
                                                                    </div>
                                                                </td>

                                                                <td className="flex px-2 py-3 whitespace-nowrap text-sm text-center text-gray-500">
                                                                    {row && row.DUE_DATE && <div className="mt-1.5 cursor-pointer z-10" onClick={(e)=> {setSampleInfoTableColumnCopyDown(row.DUE_DATE, index, 'DUE_DATE'); e.stopPropagation();}}>{getChevronDownIcon()}</div>}
                                                                    <div className={row && row.DUE_DATE ? "-ml-10" : ""}>
                                                                        <DatePicker isClearable selected={row.DUE_DATE ? new Date(row.DUE_DATE) : null} onChange={date => {setSampleInfoTableData(date, index, 'DUE_DATE')}} customInput={<SampleDueDatePicker />} dateFormat="dd/MMM/yyyy"/>
                                                                    </div>
                                                                </td>

                                                                {additionalSampleInfoColumn && additionalSampleInfoColumn.length > 0 &&
                                                                    additionalSampleInfoColumn.map((column, i) =>(
                                                                        <td key={i} className="px-2 py-3 whitespace-nowrap text-sm text-center text-gray-500">
                                                                            <div className="flex">
                                                                                <input className="py-1 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md  text-center"
                                                                                    value={row[column] || ''}
                                                                                    disabled={!column}
                                                                                    onChange={(e) => setSampleInfoTableData(e.target.value, index, column)}
                                                                                />
                                                                                {row && row[column] && <div className="-ml-7 mt-1 cursor-pointer z-10" onClick={(e)=> {setSampleInfoTableColumnCopyDown(row[column], index, column); e.stopPropagation();}}>{getChevronDownIcon()}</div>}
                                                                            </div>
                                                                        </td>))
                                                                }
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {currentTab === "Chain of Custody Details" &&
                        <div className="mt-8 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="-mt-8 col-span-6">
                                {showResponseMsg && <ResponseMessageField />}
                            </div>
                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-2">
                                        Date/Time Received
                                    </label>
                                    <div className="w-2/3">
                                        <DatePicker selected={receivedDateTime} onChange={date => {setReceivedDateTime(date)}} customInput={<ReceivedDateTimePicker />} dateFormat="dd/MMM/yyyy H:mm" timeInputLabel="Time:" showTimeInput/>
                                    </div>
                                </div>
                                {!receivedDateTime && <p className="text-red-500 text-xs italic mt-2">* Date/Time Received is required.</p>}
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                Temperature/Condition
                                </label>
                                <div className="w-1/2">
                                    <input
                                        value={temperatureCondition || ''}
                                        onChange={e => { setTemperatureCondition(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="col-span-3 mx-2">
                                <div className="flex">
                                    <label className="block text-md font-medium text-gray-700 mt-1">
                                        Received by
                                    </label>
                                    <div className="w-2/3">
                                        <input
                                            value={receivedBy || ''}
                                            onChange={e => { setReceivedBy(e.target.value); }}
                                            type="text"
                                            className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        />
                                    </div>
                                </div>
                                {!receivedBy && <p className="text-red-500 text-xs italic mt-2">* Received by is required.</p>}
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Confirmed by
                                </label>
                                <div className="w-2/3">
                                    <input
                                        value={secondCheckedBy || ''}
                                        onChange={e => { setSecondCheckedBy(e.target.value); }}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Report Date
                                </label>
                                <div className="w-2/3">
                                    <DatePicker disabled={true} selected={reportDate} onChange={date => {setReportDate(date)}} customInput={<ReportDatePicker />} dateFormat="dd/MMM/yyyy"/>
                                </div>
                            </div>

                            <div className="flex col-span-3 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Invoice Date
                                </label>
                                <div className="w-3/5">
                                    <DatePicker disabled={true} selected={invoiceDate} onChange={date => {setInvoiceDate(date)}} customInput={<InvoiceDatePicker />} dateFormat="dd/MMM/yyyy"/>
                                </div>
                            </div>

                            <div className="flex col-span-6 mx-2">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Additional Comments
                                </label>
                                <div className="w-3/4">
                                    <textarea
                                        rows={6}
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                        value={additionalComments || ''}
                                        onChange={e => { setAdditionalComments(e.target.value); }}
                                    />
                                </div>
                            </div>
                        </div>}

                        {currentTab === "Test Assginment" &&
                        <>
                            <div className="col-span-6">
                                {showResponseMsg && <div className="mt-1"><ResponseMessageField /></div>}
                            </div>
                            <div className="mt-3 w-full flex">
                                <div className='w-1/3 border-purple-500 text-gray-900 text-base leading-6 font-medium py-2 px-1 border-b-2 cursor-pointer flex'
                                    onClick={ (e) => { setShowSampleTestGrid((!selectedRow || selectedRow.Status !== "Accepted") ? false : !showSampleTestGrid); e.stopPropagation(); } }
                                >
                                    {showSampleTestGrid ? "Test Assignment" : "Test Info"}  { <div className="ml-4">{showSampleTestGrid ? getViewGridIcon() : getViewListIcon() }</div> }
                                </div>
                                {/* <div className="flex mt-2">
                                    <div className='ml-5 text-sm'>
                                        { loading ? 
                                            getSpinnerIcon()
                                            : 
                                            <input
                                                type="file"
                                                accept=".csv"
                                                value={uploadFile || ''}
                                                onChange={(e) => readUploadCsvData(e) } 
                                                onClick={() => { setUploadFile(''); setUploadFileErrMsg(null);} }/>
                                        }
                                    </div>
                                    <div className='mx-1 mt-1 text-sm text-indigo-600 hover:text-indigo-900 cursor-pointer'
                                        onClick={ () => { setTestInfo([{procedRed : '', testRef : '',  methodRef : '', note : ''}]); setTestInfoErrMsg(['']); clearSampleTestAssignment();} }
                                        >Clear all tests
                                    </div>
                                    {uploadFileErrMsg && <p className="text-red-500 text-xs italic mt-3">{uploadFileErrMsg}</p>}
                                </div> */}
                            </div>

                            <div className="h-510 overflow-y-auto pb-3">
                                
                                {!showSampleTestGrid && testInfo && testInfo.length > 0 &&
                                    testInfo.map((item, i) => (
                                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" key={i}>
                                            <div className="col-span-6 mx-2">
                                                <div className="flex">
                                                    <div className="mt-1 cursor-pointer"
                                                        onClick={() => pushIntoTestInfoArray() }>
                                                        {getPlusIcon()}
                                                    </div>
                                                    <div className="w-2/3 mx-2">
                                                        <select className={`${!item.procedRef ? 'text-gray-400' : ''} ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md`}
                                                            value={item.procedRef || ''}
                                                            onChange={e => { setInputTestInfo(i, e.target.value, 'proced') }}
                                                            //disabled={!item.testRef}>
                                                            disabled={true}>
                                                            <option className="text-gray-300" value="">Procedure</option>
                                                            {productCategoryTests && productCategoryTests.length > 0 &&
                                                                productCategoryTests.map((obj, index) => (
                                                                    obj.TestRef === item.testRef ?
                                                                    <option key={index} className="text-gray-800" value={obj.ProcedRef}>{obj.PrDescrip}</option>
                                                                    :
                                                                    <></>
                                                                ))}
                                                        </select>
                                                    </div>
                                                    <div className="w-2/3 mx-2">
                                                        <select className={`${!item.testRef ? 'text-gray-400' : ''} ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md`}
                                                            value={item.testRef || ''}
                                                            onChange={e => { setInputTestInfo(i, e.target.value, 'test') }}>
                                                            <option className="text-gray-400" value="">Test Item</option>
                                                            {productCategoryTests && productCategoryTests.length > 0 &&
                                                                productCategoryTests.map((obj, index) => (
                                                                    <option key={index} className="text-gray-800" value={obj.TestRef}>{obj.TestDescrip}</option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                    <div className="w-2/3 mx-2">
                                                        <select className={`${!item.methodRef ? 'text-gray-400' : ''} ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md`}
                                                            value={item.methodRef || ''}
                                                            onChange={e => { setInputTestInfo(i, e.target.value, 'method') }}
                                                            disabled={true}>
                                                            <option className="text-gray-300" value="">Method</option>
                                                            {productCategoryTests && productCategoryTests.length > 0 &&
                                                                productCategoryTests.map((obj, index) => (
                                                                    obj.TestRef === item.testRef ?
                                                                    <option key={index} className="text-gray-800" value={obj.MethodRef}>{obj.MethodDescrip}</option>
                                                                    :
                                                                    <></>
                                                                ))}
                                                        </select>
                                                    </div>
                                                    <div className="w-1/3 mx-2">
                                                        <select className={`${!item.unit ? 'text-gray-400' : ''} ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md`}
                                                            value={item.unit || ''}
                                                            onChange={e => { setInputTestInfo(i, e.target.value, 'unit') }}
                                                            disabled={true}>
                                                            <option className="text-gray-300" value="">Unit</option>
                                                            {productCategoryTests && productCategoryTests.length > 0 &&
                                                                productCategoryTests.map((obj, index) => (
                                                                    obj.TestRef === item.testRef ?
                                                                    <option key={index} className="text-gray-800" value={obj.Units}>{obj.Units}</option>
                                                                    :
                                                                    <></>
                                                                ))}
                                                        </select>
                                                    </div>
                                                    <div className="w-2/3 mx-2">
                                                        <input
                                                            value={item.note || ''}
                                                            onChange={e => { setInputTestInfo(i, e.target.value, 'note') }}
                                                            type="text"
                                                            placeholder="Additional Notes"
                                                            className="ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                        />
                                                    </div>
                                                    <div className="ml-4 mt-1 cursor-pointer"
                                                        onClick={() => spliceFromTestInfoArray(i) }>
                                                        {getMinusIcon()}
                                                    </div>
                                                </div>
                                                {!testInfo[i].testRef && <p className="text-red-500 text-xs italic mt-2">* Please select a test.</p>}
                                                {testInfoErrMsg[i] && <p className="text-red-500 text-xs italic mt-2">{ testInfoErrMsg[i] }</p>}
                                            </div>
                                        </div>))
                                }
                                {showSampleTestGrid && 
                                    <table className="mt-3 min-w-full divide-y divide-gray-200 overflow-x-auto">
                                        <thead className="bg-gray-50">
                                            <tr className="text-center">
                                                <th className="px-2 py-2 w-310 min-w-310 text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Test
                                                </th>
                                                {sampleInfo && sampleInfo.length > 0 &&
                                                    sampleInfo.map((s, index)=>(
                                                        <th className="px-2 py-2 min-w-20 text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:text-indigo-600"
                                                            key={index}
                                                            onClick={(e)=> {setSampleTestAssignmentColumnCopyDown(index); e.stopPropagation();}}>
                                                            {index +1 }
                                                        </th>
                                                    ))
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {testInfo && testInfo.length > 0 &&
                                                testInfo.map((test, index) =>(
                                                    <tr key={index} className="border-b border-gray-200">
                                                        <td className="px-2 py-3 whitespace-nowrap text-sm text-center text-gray-900">
                                                            <select className="w-full py-1 px-4 block shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                                value={test.testRef || ''}
                                                                disabled={true} >
                                                                <option className="text-gray-400" value="">Test Item</option>
                                                                {productCategoryTests && productCategoryTests.length > 0 &&
                                                                    productCategoryTests.map((obj, index) => (
                                                                        <option key={index} className="text-gray-800" value={obj.TestRef}>{obj.TestDescrip}</option>
                                                                    ))}
                                                            </select>  
                                                        </td>
                                                        
                                                        {sampleTestAssignment && sampleTestAssignment.length > 0 &&
                                                            sampleTestAssignment.map((s, index)=>(
                                                                <td key={index} className="px-2 py-3 whitespace-nowrap text-sm text-center text-gray-500">
                                                                    <input className="py-1 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                                        type="checkbox"
                                                                        checked={s.includes(test.testRef)}
                                                                        onChange={(e) => setSampleTestAssignmentData(test.testRef, index)}
                                                                        disabled={!(test && test.testRef)}
                                                                    />
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </>
                        }

                        {currentTab === "Limit Assignment" &&
                        <>
                            <div className="col-span-6">
                                {showResponseMsg && <div className="mt-1"><ResponseMessageField /></div>}
                            </div>
                            <div className="mt-3 w-1/3">
                                <div className='border-purple-500 text-gray-900 text-base leading-6 font-medium py-2 px-1 border-b-2 cursor-pointer flex'
                                    onClick={ (e) => { setShowManualEntryLimitGrid(!showManualEntryLimitGrid); e.stopPropagation(); } }>
                                    {showManualEntryLimitGrid ? "One-off Limits" : "Global Limits"}  { <div className="ml-4">{showManualEntryLimitGrid ? getViewGridIcon() : getViewListIcon() }</div> }
                                </div>
                            </div>

                            <div className="h-510 overflow-y-auto">
                                {!showManualEntryLimitGrid && limitGroupAssign && limitGroupAssign[0].length > 0 &&
                                    limitGroupAssign[0].map((item, i) => (
                                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" key={i}>
                                            <div className="col-span-6 mx-2">
                                                <div className="flex">
                                                    <div className="mt-1 cursor-pointer"
                                                        onClick={() => pushIntoGlobalLimitAssignArray() }>
                                                        {getPlusIcon()}
                                                    </div>
                                                    <div className="w-1/5 mx-2">
                                                        <input
                                                            value={item.limitType || ''}
                                                            type="text"
                                                            placeholder="Limit Type"
                                                            disabled={true}
                                                            className="ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                        />
                                                    </div>
                                                    <div className="w-3/5 mx-2">
                                                        <select className={`${!item.descrip ? 'text-gray-400' : ''} ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md`}
                                                            value={item.id || ''}
                                                            onChange={e => { setGlobalLimitAssignmentData(i, e.target.value, 'descrip') }}>
                                                            <option className="text-gray-300" value="">Description</option>
                                                            {clientLimitGroupList && clientLimitGroupList.length > 0 &&
                                                                clientLimitGroupList.map((limit, index) => (
                                                                    <option key={index} className="text-gray-800" value={limit.ID}>{limit.DESCRIPTION}</option>
                                                                ))}
                                                        </select>
                                                    </div>
                                                    <div className="w-1/5 mx-2">
                                                        <input
                                                            value={item.priority || ''}
                                                            onChange={e => { setGlobalLimitAssignmentData(i, e.target.value, 'priority') }}
                                                            type="number"
                                                            placeholder="Priority"
                                                            disabled={true}
                                                            className="ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                        />
                                                    </div>
                                                    <div className="ml-4 mt-1 cursor-pointer"
                                                        onClick={() => spliceFromGlobalLimitAssignArray(i) }>
                                                        {getMinusIcon()}
                                                    </div>
                                                </div>
                                                {limitGroupAssignErrMsg && limitGroupAssignErrMsg[0] && limitGroupAssignErrMsg[0].length > 0 && <p className="text-red-500 text-xs italic mt-2">{ limitGroupAssignErrMsg[0][i] }</p>}
                                            </div>
                                        </div>))
                                }
                                {showManualEntryLimitGrid && testInfo && testInfo.length > 0 &&
                                    testInfo.map((item, i) => (
                                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6" key={i}>
                                            <div className="col-span-6 mx-2">
                                                <div className="flex">
                                                    <div className="w-2/3 mx-2">
                                                        <select className={`${!item.testRef ? 'text-gray-400' : ''} ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md`}
                                                            value={item.testRef || ''}
                                                            onChange={e => { setInputTestInfo(i, e.target.value, 'test') }}
                                                            disabled={true}>
                                                            <option className="text-gray-400" value="">Test Item</option>
                                                            {productCategoryTests && productCategoryTests.length > 0 &&
                                                                productCategoryTests.map((obj, index) => (
                                                                    <option key={index} className="text-gray-800" value={obj.TestRef}>{obj.TestDescrip}</option>
                                                                ))}
                                                        </select>
                                                    </div>

                                                    <div className="w-1/4 mx-2">
                                                        <select className={`${!item.unit ? 'text-gray-400' : ''} ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md`}
                                                            value={item.unit || ''}
                                                            disabled={true}>
                                                            <option className="text-gray-300" value="">Unit</option>
                                                            {productCategoryTests && productCategoryTests.length > 0 &&
                                                                productCategoryTests.map((obj, index) => (
                                                                    obj.TestRef === item.testRef ?
                                                                    <option key={index} className="text-gray-800" value={obj.Units}>{obj.Units}</option>
                                                                    :
                                                                    <></>
                                                                ))}
                                                        </select>
                                                    </div>
                                                    <div className="w-1/4 mx-2">
                                                        <input
                                                            value={(manualEnteredLimit[i] && manualEnteredLimit[i]['lower']) || ''}
                                                            onChange={e => { setManualEnteredTestLimit(i, item.testRef, e.target.value, 'lower') }}
                                                            type="text"
                                                            placeholder="Lower"
                                                            className="ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                        />
                                                    </div>
                                                    <div className="w-1/4 mx-2">
                                                        <input
                                                            value={(manualEnteredLimit[i] && manualEnteredLimit[i]['upper']) || ''}
                                                            onChange={e => { setManualEnteredTestLimit(i, item.testRef, e.target.value, 'upper') }}
                                                            type="text"
                                                            placeholder="Upper"
                                                            className="ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                        />
                                                    </div>
                                                    <div className="w-2/3 mx-2">
                                                        <input
                                                            value={item.note || ''}
                                                            type="text"
                                                            placeholder="Additional Notes"
                                                            className="ml-2 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))
                                }
                            </div>
                        </>
                        }

                        {currentTab === 'Contact' &&
                        <>
                        <div className="col-span-6">
                            {showResponseMsg && <ResponseMessageField />}
                        </div>
                        <div className="h-510 overflow-y-auto pb-3">
                        <div className="mt-3">
                            <div className='w-1/3 border-purple-500 text-gray-900 text-base leading-6 font-medium py-2 px-1 border-b-2'>Mail To</div>
                        </div>
                        <div className="mt-10 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="flex col-span-2 mx-2">
                            <label className="block text-md font-medium text-gray-700">
                                Name
                            </label>
                            <div className="w-4/5">
                                <input
                                    value={(mainContact && mainContact.name) || ''}
                                    //onChange={e => { setMainContact(e.target.value) }}
                                    disabled={true}
                                    type="text"
                                    className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                />
                            </div>
                        </div>

                        <div className="col-span-4 mx-2">
                            <div className="flex">
                                <label className="block text-md font-medium text-gray-700 mt-1">
                                    Email
                                </label>
                                <div className="w-3/4">
                                    <input
                                        value={(mainContact && mainContact.email) || ''}
                                        //onChange={e => { setMainContact(e.target.value) }}
                                        disabled={true}
                                        type="text"
                                        className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                    />
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="mt-10">
                            <div className='w-1/3 border-purple-500 text-gray-900 text-base leading-6 font-medium py-2 px-1 border-b-2'>CC List</div>
                        </div>
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        {contactCcList && contactCcList.length > 0 && 
                            contactCcList.map((cc, i) =>(
                                <>
                                    <div className="flex col-span-2 mx-2">
                                        <label className="block text-md font-medium text-gray-700 mt-1">
                                            Name
                                        </label>
                                        <div className="w-4/5">
                                            <input
                                                value={(cc && cc.name) || ''}
                                                //onChange={e => { setMainContact(e.target.value) }}
                                                disabled={true}
                                                type="text"
                                                className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-4 mx-2">
                                        <div className="flex">
                                            <label className="block text-md font-medium text-gray-700 mt-1">
                                                Email
                                            </label>
                                            <div className="w-3/4">
                                                <input
                                                    value={(cc && cc.email) || ''}
                                                    //onChange={e => { setMainContact(e.target.value) }}
                                                    disabled={true}
                                                    type="text"
                                                    className="ml-3 py-1 px-4 block w-full shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-150 rounded-md"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>
                        </div>
                        </>
                        }
                    </div>
                    </div>
                </div>

                <div className="flex rounded-b-lg shadow bg-gray-100 justify-between">
                    <div className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <button onClick={()=>{ setShowSubmissionFormDialog(false); setSelectedRow(null); }} type="button" className="items-center px-3 py-2.5 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                            Close
                        </button>      
                    </div>

                    <div className="flex justify-between">  {/* no need for now */}
                        {false && selectedRow && <div className="px-6 py-3">
                            <button 
                                onClick={()=>{ downloadSampleSubmissionForm(selectedRow); }} 
                                type="button" 
                                className="bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 px-3 py-2.5 shadow-sm text-sm leading-4 font-medium rounded-md text-white"
                                disabled={loading}
                            >
                                { loading ? <div className="px-6">{getSpinnerIcon()}</div> : 'Download' }
                            </button>
                        </div>}

                        {((selectedRow && selectedRow.Status !== "Accepted") || !selectedRow) &&
                        <div ref={ buttonGroupOptionRef } className="relative inline-block text-left mx-5">
                            <div className="inline-flex px-6 py-3 text-xs font-medium text-gray-500">
                                <button
                                    type="button"
                                    className="bg-indigo-600 hover:bg-indigo-700 focus:outline-none px-3 py-2.5 shadow-sm text-sm leading-4 font-medium rounded-l-md text-white"
                                    onClick={(e) => {setShowButtonGroupOptions(!showButtonGroupOptions); e.stopPropagation(); }}
                                >
                                    { loading ? <div className="px-3">{getSpinnerIcon()}</div> : 'Submit' }
                                </button>
                                <div className="relative -ml-px block">
                                    <button 
                                        className="relative inline-flex items-center rounded-r-md bg-white px-2 py-2.5 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none"
                                        onClick={(e) => {setShowButtonGroupOptions(!showButtonGroupOptions); e.stopPropagation(); }}
                                        >
                                        <span className="sr-only">Open options</span>
                                        <div>{getChevronUpIcon()}</div>
                                    </button>
                                </div>
                            </div>
                            {showButtonGroupOptions && 
                            <div className="absolute top-0 right-0 left-5 -mt-52 p-2 w-3/4 rounded-md shadow-lg bg-gray-50 ring-1 ring-black ring-opacity-5">
                                {customOptions.map((option, i) => (
                                <div className="py-1 hover:bg-blue-100 rounded-md" key={i}>
                                    <div 
                                        className={'text-md text-gray-900 cursor-pointer px-2 py-1'}
                                        onClick={()=>{ selectButtonGroupOption(option) }}
                                    >
                                        {option.label}
                                    </div>
                                </div>))}
                            </div>}
                        </div>
                        }
                        {selectedRow && selectedRow.Status === "Accepted" &&
                        <div ref={ buttonGroupOptionRef } className="relative inline-block text-left mx-5">
                            <div className="inline-flex px-6 py-3 text-xs font-medium text-gray-500">
                                <button
                                    type="button"
                                    className="bg-indigo-600 hover:bg-indigo-700 focus:outline-none px-3 py-2.5 shadow-sm text-sm leading-4 font-medium rounded-l-md text-white"
                                    onClick={(e) => {setShowButtonGroupOptions(!showButtonGroupOptions); e.stopPropagation(); }}
                                >
                                    { loading ? <div className="px-6">{getSpinnerIcon()}</div> : 'Ceate Reg' }
                                </button>
                                <div className="relative -ml-px block">
                                    <button 
                                        className="relative inline-flex items-center rounded-r-md bg-white px-2 py-2.5 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-none"
                                        onClick={(e) => {setShowButtonGroupOptions(!showButtonGroupOptions); e.stopPropagation(); }}
                                        >
                                        <span className="sr-only">Open options</span>
                                        <div>{getChevronUpIcon()}</div>
                                    </button>
                                </div>
                            </div>
                            {showButtonGroupOptions && 
                            <div className="absolute top-0 right-0 left-5 -mt-14 p-2 w-3/4 rounded-md shadow-lg bg-gray-50 ring-1 ring-black ring-opacity-5">
                                <div className="py-1.5 rounded-md flex">
                                    <input
                                        value={noOfJob || 1}
                                        onChange={e => { setInputNoOfJob(e.target.value) }}
                                        type="number"
                                        disabled={true}
                                        className="py-1 px-4 block w-2/3 shadow-sm focus:outline-none border border-gray-150 rounded-l-md"
                                    />
                                    <button
                                        type="button"
                                        className="bg-green-500 hover:bg-green-600 focus:outline-none px-3 py-2.5 shadow-sm text-sm leading-4 font-medium rounded-r-md text-white"
                                        onClick={(e) => {selectButtonGroupOption(3); e.stopPropagation(); }}
                                        disabled={loading}
                                    >
                                        Ok
                                    </button>
                                </div>
                            </div>}
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SampleSubmissionForm;